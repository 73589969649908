<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-row justify="center" class="py-5 px-10 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card class="pa-0" v-if="Array.isArray(webhookMessageList)">
                        <v-app-bar color="green darken-2" dark flat dense>
                            <v-app-bar-title>Webhook Messages ({{ webhookMessageList.length }})</v-app-bar-title>
                            <v-spacer/>
                            <!-- TODO: enable invite new users only for organizations with business or enterprise add-ons; organizations on the essential plan have only one admin -->
                            <!-- <v-btn icon :to="{ name: 'organization-invite-user', params: { organizationId: this.$route.params.organizationId } }">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width></font-awesome-icon>
                            </v-btn> -->
                        </v-app-bar>
                        <v-card-text>
                            <p class="mb-0 pb-0" v-if="webhookMessageList.length === 0">
                                No messages found in queue
                            </p>
                            <v-simple-table v-if="webhookMessageList.length > 0">
                                <template #default>
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Label</th>
                                            <th>Content</th>
                                            <th>Status</th>
                                            <th>Created on</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="webhookMessage in webhookMessageList" :key="webhookMessage.id">
                                            <td>
                                                <router-link :to="viewWebhookMessageLink(webhookMessage)">{{ webhookMessageIdText(webhookMessage.id) }}</router-link>
                                            </td>
                                            <td>
                                                {{ webhookMessage.label }}
                                            </td>
                                            <td>
                                                <!-- TODO: need a json viewer maybe, and an icon with hover over for that -->
                                                {{ webhookMessage.content }}
                                            </td>
                                            <td>
                                                {{ messageStatusDisplay(webhookMessage.status) }}
                                            </td>
                                            <td>
                                                {{ formatDate(webhookMessage.created_on) }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';

function formatId(input) {
    const parts = [];
    let i = 0;
    while (i < input.length) {
        const part = input.substr(i, 5);
        parts.push(part);
        i += 5;
    }
    return parts.join('-');
}

export default {
    components: {
    },
    data: () => ({
        webhookMessageList: null,
        error: null,
        forbiddenError: false,
        account: null, // only if query specifies an account; otherwise we search all accounts
    }),
    computed: {
        ...mapState({
            organization: (state) => state.organization,
        }),
    },
    watch: {
        $route() {
            this.loadWebhookMessageList();
        },
    },
    methods: {
        async loadWebhookMessageList() {
            try {
                this.$store.commit('loading', { loadWebhookMessageList: true });
                const query = {
                    is_delivered: false,
                };
                // if (this.$route.query.account_id) {
                //     query.account_id = this.$route.query.account_id;
                // }
                const response = await this.$client.organization(this.$route.params.organizationId).webhookMessage.search(query);
                if (response?.list) {
                    this.webhookMessageList = response.list;
                } else {
                    this.webhookMessageList = null;
                }
            } catch (err) {
                console.error('loadWebhookMessageList failed', err);
            } finally {
                this.$store.commit('loading', { loadWebhookMessageList: false });
            }
        },
        viewWebhookMessageLink(item) {
            return { name: 'organization-view-webhook-message', params: { organizationId: this.$route.params.organizationId, webhookMessageId: item.id } };
        },
        formatDate(timestamp) {
            return new Date(timestamp).toLocaleDateString();
        },
        formatBooleanYesNo(value) {
            if (typeof value === 'boolean' && value) {
                return 'Yes';
            }
            if (typeof value === 'string' && ['true', 'yes'].includes(value.toLowerCase())) {
                return 'Yes';
            }
            return 'No';
        },
        messageStatusDisplay(status) {
            // TODO: message delivery status like 'delivered', 'failed', 'cancelled', 'pending', etc.
            if (status === 'delivered') {
                return 'Delivered';
            }
            if (status === 'failed') {
                return 'Failed';
            }
            if (status === 'cancelled') {
                return 'Cancelled';
            }
            if (status === 'pending') {
                return 'Pending';
            }
            if (status === 'queue') {
                return 'Queue';
            }
            return status;
        },
        webhookMessageIdText(webhookMessageId) {
            return formatId(webhookMessageId);
        },
    },
    mounted() {
        this.loadWebhookMessageList();
    },
};
</script>
